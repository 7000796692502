class Orion_TeamListBigBox {
    constructor(wrapper) {
      this.wrapper = wrapper;
      this.initState();
      this.initEvents(wrapper);
    }
  
    initState () {
      this.state = {
        itemsPerRow: 3,
        lastOpenedItemIndex: -1
      };
    }
  
    initEvents() {
      this.initUI();
      this.initRWD();
    }
  
    initUI () {       
      this.UI = {
        items: this.wrapper.find('.team-item'),
        bigBoxContents: this.wrapper.find('.team-item .text-wrapper .about-item'),
        bigBoxTriggers: this.wrapper.find('.team-item .js-open-team-desc'),
        bigBox: this.wrapper.find('.team-item-big-box'),
        bigBoxContent: this.wrapper.find('.team-item-big-box .team-item-big-box__content'),
        bigBoxClose: this.wrapper.find('.team-item-big-box .close')
      };
        
      this.UI.bigBoxClose.on('click', e => {
        e.preventDefault();
        this.closeBigBox();
      });
  
      this.UI.bigBoxTriggers.each((i, btn) => {
        $(btn).on('click', e => {
          e.preventDefault();
          this.openBigBox(parseInt($(btn).attr('data-index'), 10));
        });
      });
  
      this.UI.bigBox.slideUp(0);
    }
  
    initRWD () {
      this.fullViewport = window.matchMedia('(min-width: 1201px)');
      this.desktopViewport = window.matchMedia('(min-width: 1025px) and (max-width: 1200px)');
      this.bigTabletViewport = window.matchMedia('(min-width: 721px) and (max-width: 1024px)');
      this.tabletViewport = window.matchMedia('(min-width: 501px) and (max-width: 720px)');
      this.mobileViewport = window.matchMedia('(max-width: 500px)');
      if (this.fullViewport.matches) this.updateViewports('full');
      if (this.desktopViewport.matches) this.updateViewports('desktop');
      if (this.bigTabletViewport.matches) this.updateViewports('bigtablet');
      if (this.tabletViewport.matches) this.updateViewports('tablet');
      if (this.mobileViewport.matches) this.updateViewports('mobile');
      this.fullViewport.addListener(mq => mq.matches ? this.updateViewports('full') : false);
      this.desktopViewport.addListener(mq => mq.matches ? this.updateViewports('desktop') : false);
      this.bigTabletViewport.addListener(mq => mq.matches ? this.updateViewports('bigtablet') : false);
      this.tabletViewport.addListener(mq => mq.matches ? this.updateViewports('tablet') : false);
      this.mobileViewport.addListener(mq => mq.matches ? this.updateViewports('mobile') : false);
  
  
      if (this.fullViewport.matches) {
        this.state.itemsPerRow = 3;
      } else if (this.desktopViewport.matches) {
        this.state.itemsPerRow = 2;
      } else if (this.bigTabletViewport.matches) {
        this.state.itemsPerRow = 3;
      } else if (this.tabletViewport.matches) {
        this.state.itemsPerRow = 2;
      } else if (this.mobileViewport.matches) {
        this.state.itemsPerRow = 1;
      }
    }
  
    updateViewports (type) {
      if (this.currentViewport !== type) {
        this.currentViewport = type;
        this.state.lastOpenedItemIndex = -1;
        this.UI.bigBox.find('.team-item-big-box__content').empty();
        this.wrapper.prepend(this.UI.bigBox);
        this.UI.bigBox.slideUp(0);
  
        if (this.fullViewport.matches) {
          this.state.itemsPerRow = 3;
        } else if (this.desktopViewport.matches) {
            this.state.itemsPerRow = 2;
        } else if (this.bigTabletViewport.matches) {
          this.state.itemsPerRow = 3;
        } else if (this.tabletViewport.matches) {
            this.state.itemsPerRow = 2;
        } else if (this.mobileViewport.matches) {
          this.state.itemsPerRow = 1;
        }
      }
    }
  
    closeBigBox () {
      this.UI.bigBox.slideUp(350, () => {
        this.UI.bigBoxContent.empty();
      });
    }
  
    openBigBox (index) {
      let previousIndex = this.state.lastOpenedItemIndex;
      this.closeBigBox();
      this.state.lastOpenedItemIndex = index;
  
      setTimeout(() => {
        let previousRow = Math.floor(previousIndex / this.state.itemsPerRow);
        let currentRow = Math.floor(index / this.state.itemsPerRow);
  
        if (previousRow !== currentRow) {
          let placeIndex = ((currentRow + 1) * this.state.itemsPerRow) - 1;
  
          if (placeIndex > this.UI.items.length - 1) {
            placeIndex = this.UI.items.length - 1;
          }
  
          this.UI.bigBox.insertAfter(this.UI.items.eq(placeIndex));
        }
  
        this.UI.bigBoxContent.html(this.UI.bigBoxContents.eq(index).html());
        
        setTimeout(() => {
          this.UI.bigBox.slideDown(350);
        }, 0);
      }, 400);
    }
  }