/**
 * Testimonials slider script
 */
class Orion_TestimonialsSlider {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState() {
    this.sliderWrapper = $('.testimonials');
    this.prevBtn = this.sliderWrapper.find('.prev');
    this.nextBtn = this.sliderWrapper.find('.next');
    this.list = this.sliderWrapper.find('.testimonials-slider-list');
    this.items = this.list.find('.testimonials-slider-item');
    this.listText = this.sliderWrapper.find('.testimonials-slider-text-list');
    this.itemsText = this.sliderWrapper.find('.testimonials-slider-text-item');
    this.currentItem = 0;
    this.animationInProgress = false;
  }

  initEvents() {
    if (this.sliderWrapper.length) {
      this.swipeEffect = new Hammer(this.sliderWrapper[0]);

      this.nextBtn.on('click', e => {
        e.preventDefault();

        if (this.animationInProgress) {
          return;
        }

        this.currentItem++;
        this.animationInProgress = true;

        if (this.currentItem >= this.items.length) {
          this.currentItem = 0;
        }

        this.items.removeClass('is-active');
        this.itemsText.removeClass('is-active');
        this.items.eq(this.currentItem).addClass('is-active');
        this.list.css('margin-left', (-100 * this.currentItem) + "%");
        this.listText.css('margin-left', (-100 * this.currentItem) + "%");
        this.prevBtn.removeClass('is-hidden');

        setTimeout(() => {
          this.itemsText.eq(this.currentItem).addClass('is-active');
        }, 50);

        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });

      this.prevBtn.on('click', e => {
        e.preventDefault();

        if (this.animationInProgress) {
          return;
        }

        this.currentItem--;
        this.animationInProgress = true;

        if (this.currentItem < 0) {
          this.currentItem = this.items.length - 1;
        }

        this.items.removeClass('is-active');
        this.items.eq(this.currentItem).addClass('is-active');
        this.list.css('margin-left', (-100 * this.currentItem) + "%");
        this.listText.css('margin-left', (-100 * this.currentItem) + "%");
        this.nextBtn.removeClass('is-hidden');

        setTimeout(() => {
          this.itemsText.eq(this.currentItem).addClass('is-active');
        }, 100);

        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });

      this.swipeEffect.on('swipe', ev => {
        if (ev.type === 'swipe' && ev.direction === 2) {
          this.nextBtn.trigger('click');
        }
  
        if (ev.type === 'swipe' && ev.direction === 4) {
          this.prevBtn.trigger('click');
        }
      });
    }
  }
}
