class Orion_FormHelper {
    constructor(formElement) {
        this.formElement = formElement;
        this.rules = {
            required: {
                validator: (value, field) => {
                    if (field.attr('type') === 'checkbox') {
                        return field.prop('checked');
                    }

                    if (field.attr('type') === 'radio') {
                        let selectedValueExists = field.parents('.form-item').find('input[type="radio"]:checked').length;
                        return !!selectedValueExists;
                    }

                    if (field.attr('type') === 'file') {
                        let selectedFileExists = field[0].files.length;
                        return !!selectedFileExists;
                    }

                    return !!value;
                },
                errorText: 'Pole jest wymagane'
            },
            requiredIfDateToVisible: {
                validator: (value, field) => {
                    let dateToField = field.parents('form').find('[data-val-name="date_to"]');

                    if (dateToField.length && dateToField.hasClass('is-hidden')) {
                        return true;
                    }

                    return !!value;
                },
                errorText: 'Pole jest wymagane'
            },
            min: {
                validator: (value, range) => parseFloat(value) >= parseFloat(range),
                errorText: (range) => 'Wartość nie może być mniejsza niż ' + range
            },
            max: {
                validator: (value, range) => parseFloat(value) <= parseFloat(range),
                errorText: (range) => 'Wartość nie może być większa niż ' + range
            },
            email: {
                validator: (value) => value.indexOf('@') > 0 && value.indexOf('.') > -1,
                errorText: 'Podaj prawidłowy adres e-mail'
            },
            phone: {
              validator: (value) => (/^[\+]{0,}[0-9]{7,}$/gmi).test(value.replace(/ /gmi, '')),
              errorText: 'Podaj prawidłowy numer telefonu (może zawierać jedynie liczby i spacje)'
            },
            postcode: {
                validator: (value) => typeof value === "string" && (new RegExp(/^[0-9]{2,2}\-[0-9]{3,3}$/gmi).test(value) || new RegExp(/^[0-9]{5,5}$/gmi).test(value)),
                errorText: 'Kod pocztowy musi być podany w formacie XX-XXX'
            }
        };
        this.dependencies = {};
    }

    formDependencies(config) {
        for (let i = 0; i < config.length; i++) {
            if (config[i].useCollection) {
                let triggerSelector = config[i].dependsOn;
                let fieldName = config[i].field.replace(/[^a-z0-9\_\-]/gmi, '');
                let callback = config[i].elementsToShow;
                let triggerElement = this.formElement.find(triggerSelector);
                let fieldElements = this.formElement.find('*[data-val-name="' + fieldName + '"]');

                triggerElement.on('input', (e) => {
                    fieldElements.addClass('is-hidden');

                    for (let j = 0; j < fieldElements.length; j++) {
                        this.resetField(fieldElements.eq(j));
                    }

                    let elementsToShow = callback($(e.target).val());
                    elementsToShow.removeClass('is-hidden');
                });
            } else {
                let triggerSelector = config[i].dependsOn;
                let fieldName = config[i].field.replace(/[^a-z0-9\_\-]/gmi, '');
                let callback = config[i].showOn;
                let triggerElement = this.formElement.find(triggerSelector);
                let fieldElement = this.formElement.find('*[data-val-name="' + fieldName + '"]');

                triggerElement.on('input', (e) => {
                    if (callback($(e.target).val())) {
                        fieldElement.removeClass('is-hidden');
                    } else {
                        fieldElement.addClass('is-hidden');
                        this.resetField(fieldElement);
                    }
                });
            }
        }
    }

    resetField(fieldWrapper) {
        if (fieldWrapper.find('input').length) {
            if (fieldWrapper.find('input').attr('type') === 'radio') {
                fieldWrapper.find('input').prop('checked', false);
            } else {
                fieldWrapper.find('input').val('');
            }

            fieldWrapper.find('input').trigger('input');
        } else if (fieldWrapper.find('select').length) {
            fieldWrapper.find('select').val('');
            fieldWrapper.find('select').trigger('input');
        } else if (fieldWrapper.find('textarea').length) {
            fieldWrapper.find('textarea').val('');
            fieldWrapper.find('textarea').trigger('input');
        }
    }

    formValidate() {
        // pobieramy wszystkie pole
        let fields = this.formElement.find('*[data-val-validation]');
        let formIsValid = true;

        for (let i = 0; i < fields.length; i++) {
            // pomijamy ukryte pola
            if (fields.eq(i).parents('[data-val-name]').hasClass('is-hidden')) {
                continue;
            }

            let fieldValue = fields.eq(i).val();
            let validationRules = fields.eq(i).attr('data-val-validation');
            validationRules = validationRules.split('|');
            let fieldIsValid = true;
            let fieldErrorMessage = fields.eq(i).parents('.form-item').find('.validation-error');

            for (let j = 0; j < validationRules.length; j++) {
                let validationRule = validationRules[j];

                if (validationRule.indexOf(':') > -1) {
                    let ruleName = validationRule.split(':')[0];
                    let range = validationRule.split(':')[1];

                    if (!this.rules[ruleName].validator(fieldValue, range)) {
                        fieldIsValid = false;
                        fieldErrorMessage.text(this.rules[ruleName].errorText(range));
                        break;
                    }
                } else {
                    if (!this.rules[validationRule].validator(fieldValue, fields.eq(i))) {
                        fieldIsValid = false;
                        fieldErrorMessage.text(this.rules[validationRule].errorText);
                        break;
                    }
                }
            }

            if (fieldIsValid) {
                fieldErrorMessage.addClass('is-hidden');
                fieldErrorMessage.text('');
            } else {
                formIsValid = false;
                fieldErrorMessage.removeClass('is-hidden');
                fields.eq(i).one('input', () => {
                    fieldErrorMessage.addClass('is-hidden');
                    fieldErrorMessage.text('');
                });
            }
        }

        return formIsValid;
    }
}