/**
 * Cookie Banner
 */

/*
 Connections:
 -------------------------------------------------------------------------------
 * players - every player on website: Vimeo, YouTube, Spreaker
 * linkedin - linkedin analytics
 * newsletter - MailerLite forms
 * google - GTM code
 * fb - Facebook Pixel
 * hotjar - HotJar
 * trafficj - Traffic junky
*/

class Orion_CookieBanner {
  constructor() {
    this.config = {
      debugMode: false,
      userConsents: {
        players: false,
        linkedin: false,
        newsletter: false,
        google: false,
        fb: false,
        hotjar: false,
        trafficj: false
      },
      groups: [
        'players',
        'linkedin',
        'newsletter',
        'google',
        'fb',
        'hotjar',
        'trafficj'
      ]
    };

    this.UI = {
      basicWindow: $('.orion-cn-info'),
      extendedWindow: $('.orion-cn-info-extended'),
      buttons: {
        basicShowExtended: $('.orion-cn-info-button-show-extended'),
        basicAcceptAll: $('.orion-cn-info-button-accept-all'),
        basicRejectAll: $('.orion-cn-info-button-reject-all'),
        extendedAcceptAll: $('.orion-cn-info-extended-button-accept-all'),
        extendedSaveConfig: $('.orion-cn-info-extended-button-save-consent'),
        extendedNotNow: $('.orion-cn-info-extended-button-close-without-save')
      },
      checkboxes: {
        players: $('.orion-cn-consent-checkbox[data-consent-type="players"]'),
        linkedin: $('.orion-cn-consent-checkbox[data-consent-type="linkedin"]'),
        newsletter: $('.orion-cn-consent-checkbox[data-consent-type="newsletter"]'),
        google: $('.orion-cn-consent-checkbox[data-consent-type="google"]'),
        fb: $('.orion-cn-consent-checkbox[data-consent-type="fb"]'),
        hotjar: $('.orion-cn-consent-checkbox[data-consent-type="hotjar"]'),
        trafficj: $('.orion-cn-consent-checkbox[data-consent-type="trafficj"]')
      }
    };

    if (this.UI.basicWindow.length) {
      this.initEvents();
      this.initPublicAPI();
      this.checkConfig();
    }
  }

  checkConfig () {
    let config = Cookies.get('orion-cn-config-v3');

    // When no config - show popup
    if (!config) {
      if (this.config.debugMode) {
        console.log('⛔️ Consent config not found - showing popup');
      }

      this.showPopup();
      return;
    }

    // otherwise - load config without showing popup
    this.loadConfig(config);
  }

  loadConfig (configFromCookie) {
    this.config.userConsents.players = configFromCookie.indexOf('players') > -1;
    this.config.userConsents.linkedin = configFromCookie.indexOf('linkedin') > -1;
    this.config.userConsents.newsletter = configFromCookie.indexOf('newsletter') > -1;
    this.config.userConsents.google = configFromCookie.indexOf('google') > -1;
    this.config.userConsents.fb = configFromCookie.indexOf('fb') > -1;
    this.config.userConsents.hotjar = configFromCookie.indexOf('hotjar') > -1;
    this.config.userConsents.trafficj = configFromCookie.indexOf('trafficj') > -1;

    if (this.config.debugMode) {
      console.log('⚙️ Founded config:', JSON.parse(JSON.stringify(this.config.userConsents)));
    }
  }

  initEvents () {
    $(document).find('a[href="#cookie-settings"]').on('click', e => {
      e.preventDefault();
      this.showExtendedPopup();
    });

    this.UI.buttons.basicShowExtended.on('click', e => {
      e.preventDefault();
      this.closePopup();
      this.showExtendedPopup();
    });

    this.UI.buttons.basicAcceptAll.on('click', e => {
      e.preventDefault();
      this.giveConsentForAll();
      this.closePopup();
    });

    this.UI.buttons.basicRejectAll.on('click', e => {
      e.preventDefault();
      this.resetCookieConfig();
      this.closePopup();
    });

    this.UI.buttons.extendedAcceptAll.on('click', e => {
      e.preventDefault();
      this.giveConsentForAll();
      this.closeExtendedPopup();
    });

    this.UI.buttons.extendedSaveConfig.on('click', e => {
      e.preventDefault();
      this.saveConsent();
      this.closeExtendedPopup();
    });

    this.UI.buttons.extendedNotNow.on('click', e => {
      e.preventDefault();
      this.closeExtendedPopup();
    });
  }

  setCheckboxes () {
    for (let i = 0; i < this.config.groups.length; i++) {
      let group = this.config.groups[i];
      
      if (this.config.userConsents[group]) {
        this.UI.checkboxes[group].prop('checked', true);
      } else {
        this.UI.checkboxes[group].prop('checked', false);
      }
    }
  }

  initPublicAPI () {
    window.orionCookieBanner = {
      giveConsentAndSave: (type) => this.giveConsentAndSave(type),
      giveConsentAndSaveAndLoad: (type) => this.giveConsentAndSave(type, true),
      showExtendedPopup: () => this.showExtendedPopup,
      getConsentStatus: (type) => this.getConsentStatus(type)
    };
  }

  showPopup () {
    this.UI.basicWindow.removeClass('is-hidden');
  }

  closePopup () {
    this.UI.basicWindow.addClass('is-hidden');
  }

  showExtendedPopup () {
    this.UI.extendedWindow.removeClass('is-hidden');
    $(document.body).addClass('no-scroll');
    this.setCheckboxes();
  }

  closeExtendedPopup () {
    this.UI.extendedWindow.addClass('is-hidden');
    $(document.body).removeClass('no-scroll');
  }

  giveConsentForAll () {
    this.giveConsent('players');
    this.giveConsent('linkedin');
    this.giveConsent('newsletter');
    this.giveConsent('google');
    this.giveConsent('fb');
    this.giveConsent('hotjar');
    this.giveConsent('trafficj');
    this.saveConsentConfigInCookie();
  }

  giveConsent (type, forceOnLoad = false) {
    if (this.config.debugMode) {
      console.log('✅ User gave consent:', type);
    }

    this.config.userConsents[type] = true;

    if (type === 'players') {
      this.allowPlayers();
      return;
    }

    if (type === 'linkedin') {
      this.allowLinkedin();
      return;
    }

    if (type === 'newsletter') {
      this.allowNewsletter();
      return;
    }

    if (type === 'google') {
      this.allowGoogle();
      return;
    }

    if (type === 'fb') {
      this.allowFB();
      return;
    }

    if (type === 'hotjar') {
      this.allowHotJar();
      return;
    }

    // TrafficJ not requires any direct action after allowing it
  }

  giveConsentAndSave (type, forceOnLoad = false) {
    this.giveConsent(type, forceOnLoad);
    this.saveConsentConfigInCookie();
  }

  saveConsent () {
    let consentCompare = this.compareConsents();
    this.setConsentBasedOnCheckboxes();

    // if some consent has been removed
    if (consentCompare === 'some-rejected') {
      this.saveConsentConfigInCookie();
      window.location.reload();
      return;
    }

    // otherwise - if some consent has been added
    this.saveConsentConfigInCookie();
  }

  setConsentBasedOnCheckboxes () {
    for (let i = 0; i < this.config.groups.length; i++) { 
      let group = this.config.groups[i];

      if (!!this.UI.checkboxes[group].prop('checked')) {
        this.giveConsent(group);
      }
      
      this.config.userConsents[group] = !!this.UI.checkboxes[group].prop('checked');
    }
  }

  saveConsentConfigInCookie () {
    let configToSave = [];

    for (let i = 0; i < this.config.groups.length; i++) {
      let group = this.config.groups[i];

      if (this.config.userConsents[group]) {
        configToSave.push(group);
      }
    }

    if (configToSave.length) {
      Cookies.set('orion-cn-config-v3', configToSave.join(';'), { expires: 180 });

      if (this.config.debugMode) {
        console.log('🍪 Consent config saved in the cookie');
      }
    } else {
      this.resetCookieConfig();
    }
  }

  resetCookieConfig () {
    Cookies.set('orion-cn-config-v3', 'null', { expires: 180 });

    if (this.config.debugMode) {
      console.log('🗑 User rejected all cookies - save empty config cookie');
    }
  }

  compareConsents () {
    let someRejected = false;

    for (let i = 0; i < this.config.groups.length; i++) {
      let group = this.config.groups[i];

      if (this.config.userConsents[group] && !this.UI.checkboxes[group].prop('checked')) {
        someRejected = true;
      }
    }

    if (someRejected) {
      if (this.config.debugMode) {
        console.log('🔄 Some consents has been rejected - website reload needed');
      }

      return 'some-rejected';
    }

    if (this.config.debugMode) {
      console.log('ℹ️ User not rejected any consents - website reload not needed');
    }

    return 'some-added-or-not-changed';
  }

  getConsentStatus (type) {
    return !!this.config.userConsents[type];
  }

  allowPlayers () {
    if (window.orionUnlocked.indexOf('players') > -1) {
      return;
    }

    window.orionUnlockPlayers(true);
  }

  allowNewsletter () {
    if (window.orionUnlocked.indexOf('newsletter') > -1) {
      return;
    }

    window.orionUnlockConsentedScript('newsletter', 'body', true, true);
  }

  allowGoogle () {
    if (window.orionUnlocked.indexOf('google') > -1) {
      return;
    }

    window.orionUnlockConsentedScript('google', 'head', true);
  }

  allowLinkedin () {
    if (window.orionUnlocked.indexOf('linkedin') > -1) {
      return;
    }

    window.orionUnlockConsentedScript('linkedin', 'head', true);
  }

  allowFB () {
    if (window.orionUnlocked.indexOf('fb') > -1) {
      return;
    }

    window.orionUnlockConsentedScript('fb', 'head', true);
  }

  allowHotJar () {
    if (window.orionUnlocked.indexOf('hotjar') > -1) {
      return;
    }

    window.orionUnlockConsentedScript('hotjar', 'head', true);
  }
}
