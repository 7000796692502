/**
 * UI elements
 */
class Orion_UI {
  constructor() {
    this.initSmoothScroll();
    this.detectEdge();
    this.changeColorList();
    this.initTextAccordion();
    this.initOpenSearchInput();
    this.showMoreGalleryImages();
    this.initReserveListPopup();
    this.initExpandText();
    this.showFooterButton();
    this.showProgram();
    this.fixedTripMenu();
    this.activeMenuSection();
    this.showDeadline();
    this.showCurrentTab();
    this.showDescription();
  }

  showFooterButton() {
    let goTopButton = document.querySelector('.go-top');
    if (!goTopButton) return;
    window.addEventListener('scroll', function () {
      if (window.scrollY > 500) {
        goTopButton.style.opacity = '1';
        goTopButton.style.zIndex = '22'
      } else {
        goTopButton.style.opacity = '0';
        goTopButton.style.zIndex = '-4'
      }
    })
  }

  showProgram() {
    let programContainer = document.querySelectorAll('.program-list.has-more-sections');
    if (!programContainer.length) {
      return
    }
    for (let i = 0; i < programContainer.length; i++) {
      let buttonProgram = programContainer[i].querySelector('.see-more-programs');
      let hiddenPrograms = programContainer[i].querySelectorAll('.wp-block-media-text.with_hide_content');

      buttonProgram.addEventListener('click', function (e) {
        e.preventDefault();
        hiddenPrograms.forEach(elements => {
          elements.classList.remove('hidden');
        })
        buttonProgram.style.display = 'none';
      })
    }
  }

  initSmoothScroll() {
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function (event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - 180
          }, 1000);
        }
      }
    });
  }

  detectEdge() {
    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      document.body.classList.add('browser--is-edge')
    }
  }

  changeColorList() {
    let colorList = $('.color-list-item');

    if (!colorList.length) {
      return
    }

    for (let i = 0; i < colorList.length; i++) {
      let colorListDataColor = colorList.eq(i).attr('data-color');
      colorList.eq(i).find('.color-list li .color-dot').css('background', colorListDataColor);
    }
  }

  initTextAccordion() {
    if ($(document.body).hasClass('js-ui-accordion-initialized')) {
      return;
    }

    $(document.body).addClass('js-ui-accordion-initialized');

    $(".js-accordion").each(function (i, item) {
      $(item).find('.js-accordion-content').css('height', 0);

      $(item).on("click", function () {
        let item = $(this);
        let contentWrapper = item.find('.js-accordion-content');
        let contentText = item.find('.js-accordion-content > div');
        let contentTitle = item.find('.js-accordion-title > p');
        let contentTitleText = item.find('.js-accordion-title > p > .text');

        item.toggleClass('active');
        let newHeight = contentText.outerHeight();

        if (item.hasClass('active')) {
          contentWrapper.css('height', 0);

          setTimeout(function () {
            contentWrapper.css('height', newHeight + 'px');
          }, 0);

          setTimeout(function () {
            contentWrapper.css('height', 'auto');
          }, 500);

          if (item.hasClass('block-accordion__item--file')) {
            contentTitleText.text(contentTitle.attr('data-text-less'));
          }
        } else {
          contentWrapper.css('height', newHeight + 'px');

          setTimeout(function () {
            contentWrapper.css('height', 0);
          }, 0);

          if (item.hasClass('block-accordion__item--file')) {
            contentTitleText.text(contentTitle.attr('data-text-more'));
          }
        }
      });
    });
  }

  initOpenSearchInput() {
    let searchWrapper = $('.search-block');
    let searchIcon = searchWrapper.find('.open-search');
    let searchInput = searchWrapper.find('.search-form');

    $(window).on('click', (e) => {
      searchInput.removeClass('is-active');
    });

    searchIcon.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      searchInput.toggleClass('is-active').focus();
    });

    searchInput.on('click', (e) => {
      e.stopPropagation();
    })
  }

  showMoreGalleryImages() {
    let showMoreImages = $('.show-more-gallery');

    if (!showMoreImages.length) {
      return
    }

    let gallery = showMoreImages.parents('.gallery-grid-wrapper');
    let galleryMoreImages = gallery.find('.gallery-other-images');

    if (galleryMoreImages.children().length == 0) {
      showMoreImages.addClass('is-hidden');
    }

    showMoreImages.on('click', (e) => {
      e.preventDefault();
      galleryMoreImages.removeClass('is-hidden');
      showMoreImages.addClass('is-hidden');
    });
  }

  initReserveListPopup() {
    let reservePopup = $('.reserve-list-popup');
    let openPopupBtn = $('#show-reserve-form-button');
    let closeBtn = reservePopup.find('.popup-close');

    openPopupBtn.on('click', (e) => {
      e.preventDefault();
      $(document.body).addClass('no-scroll');
    });

    closeBtn.on('click', (e) => {
      e.preventDefault();
      reservePopup.removeClass('active');
      $(document.body).removeClass('no-scroll');
    });
  }

  initExpandText() {
    let expandText = document.querySelectorAll('.expand-text');

    for (let i = 0; i < expandText.length; i++) {
      if (!expandText[i].getAttribute('data-js-initialized')) {
        expandText[i].setAttribute('data-js-initialized', true);

        let expandLink = expandText[i].querySelector('.expand-text__link');
        let expandTextContent = expandText[i].querySelector('.expand-text__inner');
        let expandTextContentText = expandText[i].querySelector('.expand-text__inner > div');

        if (expandLink) {
          expandTextContent.style.height = 0 + 'px';
          expandLink.classList.remove('expand-text__link--open');
          expandLink.addEventListener('click', (e) => {
            e.preventDefault();
            expandTextContent.classList.toggle('expand-text__inner--expanded');
            let newHeight = expandTextContentText.offsetHeight;

            if (expandTextContent.classList.contains('expand-text__inner--expanded')) {
              expandTextContent.style.height = 0 + 'px';
              expandLink.classList.remove('expand-text__link--open');
              expandLink.innerText = expandLink.getAttribute('data-text-less');

              setTimeout(() => {
                expandTextContent.style.height = (newHeight + 'px');
                expandLink.classList.add('expand-text__link--open');
              }, 0);

              setTimeout(() => {
                expandTextContent.style.height = 'auto';
              }, 500);
            } else {
              expandTextContent.style.height = (newHeight + 'px');
              expandLink.classList.add('expand-text__link--open');
              expandLink.innerText = expandLink.getAttribute('data-text-more');

              setTimeout(() => {
                expandTextContent.style.height = 0 + 'px';
                expandLink.classList.remove('expand-text__link--open');
              }, 0);
            }
          });
        }
      }
    }
  }

  fixedTripMenu() {
    var tripMenu = $('.navigation-inner-page');
    var headerHeight = document.querySelector('.page-header');
    var mobileViewport = window.matchMedia("screen and (max-width: 1024px)");
    if (!mobileViewport.matches) {
      return
    }
    $(window).on('scroll', () => {
      let positionYScroll = $(window).scrollTop();

      if (!tripMenu || !headerHeight) {
        return
      }

      if (positionYScroll > (headerHeight.getBoundingClientRect().height + 150) && !tripMenu.hasClass('fixed-menu')) {
        tripMenu.addClass('fixed-menu');
        return;
      }

      if (positionYScroll <= (headerHeight.getBoundingClientRect().height + 150) && tripMenu.hasClass('fixed-menu')) {
        tripMenu.removeClass('fixed-menu');
      }
    });

    $(window).trigger('scroll');
  }

  activeMenuSection() {
    let pageOffSetPosition;
    var mobileViewport = window.matchMedia("screen and (max-width: 1024px)");
    let menu = document.querySelector('.sidebar-menu');
    if (!menu) {
      return
    }
    let sections = document.querySelectorAll('DIV[ID]');
    if (!sections.length) {
      return
    }
    if (!mobileViewport.matches) {
      pageOffSetPosition = document.querySelector('.site-header').getBoundingClientRect().height + 115;
    } else {
      pageOffSetPosition = document.querySelector('.site-header').getBoundingClientRect().height + document.querySelector('.site-header').getBoundingClientRect().height + 40;
    }

    window.addEventListener('scroll', function () {
      let current = '';
      document.querySelectorAll('.navigation-inner-page a').forEach(link => {
        link.classList.remove('actived');
      })
      sections.forEach((section) => {
        if (section.getBoundingClientRect().top <= pageOffSetPosition) {
          current = section.getAttribute('id');
          sections.forEach(elem => {
            elem.classList.remove('actived');
          })
          section.classList.add('actived');
        }
      })
      if (!current) {
        return
      } else {
        document.querySelectorAll('.navigation-inner-page a').forEach(link => {
          if (link.classList.contains('actived')) {
            return
          } else {
            if (link.href.includes(current)) {
              this.document.querySelector('.actived').classList.remove('actived');
              link.classList.add('actived');
            }
          }
        })
      }
    })
  }

  showDeadline() {
    let containerReservation = document.querySelector('.form_date_check');
    if (!containerReservation) {
      return
    }
    let deadlineList = containerReservation.querySelector('UL');
    let deadlineContainer = containerReservation.querySelector('.content_container');
    let deadlineItem = deadlineList.querySelectorAll('LI A');
    let heightElement = deadlineList.scrollHeight;
    for (let i = 0; i < deadlineItem.length; i++) {
      if (deadlineItem[i].classList.contains('current')) {
        deadlineItem[i].parentElement.remove();
        deadlineContainer.append(deadlineItem[i]);
      }

      if (deadlineItem.length < 2) {
        deadlineContainer.classList.add('without-menu')
      }

      if (deadlineItem[i].classList.contains('current')) {
        deadlineItem[i].addEventListener('click', function (e) {
          e.preventDefault();
          if (deadlineItem.length > 1) {
            if (deadlineList.classList.contains('active')) {
              deadlineContainer.classList.remove('active');
              setTimeout(() => {
                deadlineList.classList.remove('active');
              }, 400);
              deadlineList.style.height = `0px`;
            } else {
              if (deadlineItem.length >= 4) {
                deadlineList.style.height = `270px`;
              } else {
                setTimeout(() => {
                  deadlineList.style.height = `auto`;
                }, 100);
                deadlineList.style.height = `270px`;
              }
              deadlineList.classList.add('active');
              deadlineContainer.classList.add('active');
            }
          }
        })
      }
    }
  }

  showCurrentTab() {
    let currentTabList = document.querySelectorAll('.navigation-inner-page');
    if (currentTabList.length > 0) {
      for (let i = 0; i < currentTabList.length; i++) {
        let currentTabItemsActive = currentTabList[i].querySelector('li.active');
        if (currentTabItemsActive) {
          currentTabItemsActive.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
        }
      }
    }
  }

  showDescription() {
    let descProductButton = document.querySelectorAll('.js-open-product-desc');
    if (descProductButton.length > 0) {
      for (let i = 0; i < descProductButton.length; i++) {
        descProductButton[i].addEventListener('click', function (e) {
          e.preventDefault();
          descProductButton[i].parentElement.querySelector('.about-item').classList.toggle('active');
          setTimeout(() => {
            if (descProductButton[i].parentElement.querySelector('.about-item').classList.contains('active')) {
              descProductButton[i].innerText = 'Pokaż mniej';
            } else {
              descProductButton[i].innerText = 'Dowiedz się więcej';
            }
          }, 10);
        })
      }
    }
  }
}