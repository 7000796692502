/**
 * Homepage slider script
 */
class Orion_HomeSlider {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState() {
    this.sliderWrapper = $('.home-slider');
    this.prevBtn = this.sliderWrapper.find('.prev');
    this.nextBtn = this.sliderWrapper.find('.next');
    this.list = this.sliderWrapper.find('.home-slider-list');
    this.items = this.list.find('.home-slider-item');
    this.currentItem = 0;
    this.animationInProgress = false;
  }

  initEvents() {
    setTimeout(() => {
      this.showNextSlide();
    }, 4500);

    if (this.sliderWrapper.length) {
      this.swipeEffect = new Hammer(this.sliderWrapper[0]);

      this.nextBtn.on('click', e => {
        e.preventDefault();

        if (e.originalEvent) {
          this.blankSlide = true;
        }

        if (this.animationInProgress) {
          return;
        }

        this.currentItem++;
        this.animationInProgress = true;

        if (this.currentItem >= this.items.length) {
          this.currentItem = 0;
        }

        this.list.css('margin-left', (-100 * this.currentItem) + "%");

        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });

      this.prevBtn.on('click', e => {
        e.preventDefault();

        if (e.originalEvent) {
          this.blankSlide = true;
        }

        if (this.animationInProgress) {
          return;
        }

        this.currentItem--;
        this.animationInProgress = true;

        if (this.currentItem < 0) {
          this.currentItem = this.items.length - 1;
        }

        this.list.css('margin-left', (-100 * this.currentItem) + "%");

        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });

      this.swipeEffect.on('swipe', ev => {
        if (ev.type === 'swipe' && ev.direction === 2) {
          this.nextBtn.trigger('click');
        }
  
        if (ev.type === 'swipe' && ev.direction === 4) {
          this.prevBtn.trigger('click');
        }
      });
    }
  }

  showNextSlide() {
    if (this.blankSlide) {
      this.blankSlide = false;
    } else {
      this.nextBtn.trigger('click');
    }

    setTimeout(() => {
      this.showNextSlide();
    }, 5500);
  }
}
