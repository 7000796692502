/**
 * Contact form script
 */
class Orion_ContactForm {
    constructor() {
        this.initState();
    }

    initState() {
        this.contactForm = $('.contact-form');
        this.submitContactForm = this.contactForm.find('button[type="submit"]');

        if (!this.contactForm.length) {
            return
        }

        this.initEvents();
    }

    initEvents() {
        let formHelper = new Orion_FormHelper(this.contactForm);

        this.submitContactForm.on('click', e => {
            e.preventDefault();
            let result = formHelper.formValidate();

            if (result) {
                this.contactForm.addClass('is-loading');

                setTimeout(() => {
                    let form = $('.contact-form');
                    form.find('.form-validation-error').addClass('is-hidden');
                    form.find('.form-validation-fail').addClass('is-hidden');

                    $.ajax({
                        type: 'POST',
                        url: form.attr('action'),
                        data: form.serialize()
                    }).done((data) => {
                        console.log(data.message)
                        let output = [];
                        let messageFields = Object.keys(data.message);

                        for (let i = 0; i < messageFields.length; i++) {
                            let field = messageFields[i];
                            if (data.message[field].length) {
                                output.push(data.message[field][0]);
                            }
                        }

                        form.find('.form-validation-success').html(output);
                        form.find('.form-validation-success').removeClass('is-hidden');
                    }).fail((data) => {
                        if (data.responseJSON && data.responseJSON.message) {
                            let output = [];
                            let messageFields = Object.keys(data.message);

                            for (let i = 0; i < messageFields.length; i++) {
                                let field = messageFields[i];
                                if (data.message[field].length) {
                                    output.push(data.message[field][0]);
                                }
                            }

                            form.find('.form-validation-error').html(output);
                        } else {
                            form.find('.form-validation-fail').removeClass('is-hidden');
                        }
                    }).always(() => {
                        this.contactForm.removeClass('is-loading');
                    });
                }, 2000);
            }
        });
    }
}