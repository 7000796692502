/**
 * Category Trips Filters script
 */
class Orion_CategoryTripsFilters {
  constructor() {
    this.initState();
  }

  initState() {
    this.categoryTrips = $(".category-trips");
    this.categoryForm = $(".category-form");
    this.categoryFormSelect = this.categoryForm.find("select");
    this.resetFilters = this.categoryTrips.find(".reset-filters");
    this.filterCategoryClear = this.categoryTrips.find(
      ".filter-category .clear"
    );
    this.filterBatchClear = this.categoryTrips.find(".filter-batch .clear");
    this.filterDirectionClear = this.categoryTrips.find(
      ".filter-direction .clear"
    );
    this.filterAgeClear = this.categoryTrips.find(".filter-age .clear");

    if (this.categoryTrips.length) {
      this.categoryForm.find("select").on("change", () => {
        this.setTrips();
      });

      this.initEvents();
    }
  }

  setTrips() {
    let category_id = $('.category-form select[name="category_id"]').val();
    let batch_id = $('.category-form select[name="batch_id"]').val();
    let direction = $('.category-form select[name="direction"]').val();
    let age = $('.category-form select[name="age"]').val();

    $(".reset-filters").removeClass("hidden");

    if (category_id != "") {
      $(".filter-category .value").text(
        $('.category-form select[name="category_id"] option:selected').text()
      );
      $(".filter-category").removeClass("hidden");
    } else {
      $(".filter-category .value").text("");
      $(".filter-category").addClass("hidden");
    }

    if (batch_id != "") {
      $(".filter-batch .value").text(
        $('.category-form select[name="batch_id"] option:selected').text()
      );
      $(".filter-batch").removeClass("hidden");
    } else {
      $(".filter-batch .value").text("");
      $(".filter-batch").addClass("hidden");
    }

    if (age != "") {
      $(".filter-age .value").text(
        $('.category-form select[name="age"] option:selected').text() + " lat"
      );
      $(".filter-age").removeClass("hidden");
    } else {
      $(".filter-age .value").text("");
      $(".filter-age").addClass("hidden");
    }

    if (direction != "") {
      $(".filter-direction .value").text(
        $('.category-form select[name="direction"] option:selected').text()
      );
      $(".filter-direction").removeClass("hidden");
    } else {
      $(".filter-direction .value").text("");
      $(".filter-direction").addClass("hidden");
    }

    $(".trip-item").each((i, item) => {
      let show_trip = true;

      let trip_category_id = $(item).data("category");
      let trip_batch_id = $(item).data("batch");
      let trip_age_from = $(item).data("age-from");
      let trip_direction = $(item).data("direction");
      let trip_age_to = $(item).data("age-to");

      if (category_id != "" && category_id != trip_category_id) {
        show_trip = false;
      } else if (batch_id != "" && (!trip_batch_id || typeof trip_batch_id !== "string" || !trip_batch_id.split(",").includes(batch_id))) {
        show_trip = false;
      } else if (direction != "" && direction != trip_direction) {
        show_trip = false;
      } else if (age != "" && (age < trip_age_from || age > trip_age_to)) {
        show_trip = false;
      }

      if (show_trip) {
        $(item).addClass("active");
      } else {
        $(item).removeClass("active");
      }
    });
  }

  initEvents() {
    this.resetFilters.on("click", () => {
      this.categoryFormSelect.val("").change();
      this.categoryTrips.find(".filter").addClass("hidden");
      $(".reset-filters").addClass("hidden");
      return false;
    });

    this.filterCategoryClear.on("click", () => {
      $('.category-form select[name="category_id"]').val("").change();
      this.categoryTrips.find(".filter-category").addClass("hidden");
    });

    this.filterBatchClear.on("click", () => {
      $('.category-form select[name="batch_id"]').val("").change();
      this.categoryTrips.find(".filter-batch").addClass("hidden");
    });

    this.filterAgeClear.on("click", () => {
      $('.category-form select[name="age"]').val("").change();
      this.categoryTrips.find(".filter-age").addClass("hidden");
    });

    this.filterDirectionClear.on("click", () => {
      $('.category-form select[name="direction"]').val("").change();
      this.categoryTrips.find(".filter-direction").addClass("hidden");
    });
  }
}
