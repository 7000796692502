/**
 * Menu script
 */
class Orion_Menu {
 constructor() {
  this.initState();
  this.initEvents();
 }

 initState() {
  this.win = $(window);
  this.menuWrapper = $('.site-header');
  this.menuIcon = this.menuWrapper.find('.menu-toggle');
  this.menu = this.menuWrapper.find('.menu-block');
  this.menuItemSubmenu = this.menuWrapper.find('.has-children');
  this.mobileViewport = window.matchMedia("screen and (max-width: 1024px)");
 }

 initEvents() {
  this.menuItemSubmenu.append("<span class='arrow'></span>");

  $(window).on('scroll', () => {
   let positionY = this.win.scrollTop();

   if (positionY > 25 && !this.menuWrapper.hasClass('is-fixed')) {
    this.menuWrapper.addClass('is-fixed');
    return;
   }

   if (positionY <= 25 && this.menuWrapper.hasClass('is-fixed')) {
    this.menuWrapper.removeClass('is-fixed');
   }
  });

  $(window).trigger('scroll');

  if (this.menuIcon.length) {
   this.menuIcon.on('click', e => {
    e.preventDefault();
    this.menu.toggleClass('is-active');
    this.menuIcon.toggleClass('is-active');
    $(document.body).toggleClass('no-scroll');

    setTimeout(() => {
     this.menu.toggleClass('is-showing');
    }, 150);
   });
  }

  setTimeout(() => {
   let menuSubmenuToggle = this.menuItemSubmenu.find('.arrow');
   menuSubmenuToggle.each((i, item) => {
    $(item).on('click', (e) => {
     if (this.mobileViewport.matches) {
      e.stopPropagation();
      $(item).parent().toggleClass('is-open');
     }
    });
   });
  }, 0);
 }
}