/**
 * Tabs trips script
 */
class Orion_TabsTripsSlider {
    constructor(wrapper) {
        this.sliderWrapper = wrapper;
        this.initEvents();
    }

    initEvents() {
        this.prevBtn = this.sliderWrapper.find('.prev');
        this.nextBtn = this.sliderWrapper.find('.next');
        this.list = this.sliderWrapper.find('.trips-list');
        this.items = this.list.find('.trip-item');
        this.currentItem = this.list.attr('data-current-item');
        this.itemsPerPage = 4;
        this.animationInProgress = false;
        this.initRWD();

        if (this.sliderWrapper.length) {
            this.initUI();
        }
    }

    initUI() {
        this.swipeEffect = new Hammer(this.sliderWrapper[0]);

        this.prevBtn.on('click', e => {
            e.preventDefault();

            if (this.animationInProgress) {
                return;
            }

            this.currentItem--;
            this.animationInProgress = true;

            if (this.currentItem === 0) {
                this.prevBtn.addClass('is-hidden');
            }

            this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
            this.nextBtn.removeClass('is-hidden');

            setTimeout(() => {
                this.animationInProgress = false;
            }, 250);
        });

        this.nextBtn.on('click', e => {
            e.preventDefault();

            if (this.animationInProgress) {
                return;
            }

            this.currentItem++;
            this.animationInProgress = true;

            if (this.currentItem === (this.items.length - this.itemsPerPage)) {
                this.nextBtn.addClass('is-hidden');
            }

            if (this.currentItem >= this.items.length) {
                this.currentItem = (this.items.length - 1);
            }

            this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
            this.prevBtn.removeClass('is-hidden');

            setTimeout(() => {
                this.animationInProgress = false;
            }, 250);
        });

        this.swipeEffect.on('swipe', ev => {
            if (ev.type === 'swipe' && ev.direction === 2 && ev.deltaX < -30) {
                if (this.currentItem > this.items.length - this.itemsPerPage) {
                    this.currentItem = 0;
                }

                this.nextBtn.trigger('click');
            }

            if (ev.type === 'swipe' && ev.direction === 4 && ev.deltaX > 30) {
                if (this.currentItem <= 0) {
                    return
                }

                this.prevBtn.trigger('click');
            }
        });

        if (this.items.length === 0) {
            var content = this.list.parents('.tabcontent');
            var paragraph = '<p class="no-trip">Brak aktualnych ofert</p>';
            content.find('.tabcontent-inner').css('display', 'none');
            content.find('.btn').text('Zobacz wyjazdy archiwalne');
            content.prepend(paragraph)
        }
    }

    initRWD() {
        this.fullHDViewport = window.matchMedia('(min-width: 1201px)');
        this.desktopViewport = window.matchMedia('(min-width: 1025px) and (max-width: 1200px)');
        this.tabletViewport = window.matchMedia('(min-width: 561px) and (max-width: 1024px)');
        this.mobileViewport = window.matchMedia('(max-width: 560px)');
        if (this.desktopViewport.matches) this.updateViewports('fullhd');
        if (this.desktopViewport.matches) this.updateViewports('desktop');
        if (this.tabletViewport.matches) this.updateViewports('tablet');
        if (this.mobileViewport.matches) this.updateViewports('mobile');
        this.fullHDViewport.addListener(mq => mq.matches ? this.updateViewports('fullhd') : false);
        this.desktopViewport.addListener(mq => mq.matches ? this.updateViewports('desktop') : false);
        this.tabletViewport.addListener(mq => mq.matches ? this.updateViewports('tablet') : false);
        this.mobileViewport.addListener(mq => mq.matches ? this.updateViewports('mobile') : false);

        if (this.fullHDViewport.matches) {
            if (this.items.length <= 4) {
                this.prevBtn.css('display', 'none')
                this.nextBtn.css('display', 'none')
            }
            this.itemsPerPage = 4;
        } else if (this.desktopViewport.matches) {
            if (this.items.length <= 3) {
                this.prevBtn.css('display', 'none')
                this.nextBtn.css('display', 'none')
            }
            this.itemsPerPage = 3;
        } else if (this.tabletViewport.matches) {
            if (this.items.length <= 2) {
                this.prevBtn.css('display', 'none')
                this.nextBtn.css('display', 'none')
            }
            this.itemsPerPage = 2;
        } else if (this.mobileViewport.matches) {
            if (this.items.length <= 1) {
                this.prevBtn.css('display', 'none')
                this.nextBtn.css('display', 'none')
            }
            this.itemsPerPage = 1;
        }

        this.fullHDViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(4);
            }
        });

        this.desktopViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(3);
            }
        });

        this.tabletViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(2);
            }
        });

        this.mobileViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(1);
            }
        });
    }

    updateViewports(type) {
        if (this.currentViewport !== type) {
            this.currentItem = 0;
            this.list.css('margin-left', "0%");
            this.currentViewport = type;
            this.prevBtn.addClass('is-hidden');
            this.nextBtn.removeClass('is-hidden');
        }
    }

    setItemsPerPage(numberOfItems) {
        this.currentItem = 0;
        this.itemsPerPage = numberOfItems;
        this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
    }
}
