class Orion_TeamSliderSmallBigBox {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.initState();
        this.initEvents(wrapper);
    }

    initState() {
        this.state = {
            itemsPerRow: 3,
            lastOpenedItemIndex: -1
        };
    }

    initEvents() {
        this.initUI();
    }

    initUI() {
        this.UI = {
            items: this.wrapper.find('.team-slider-item'),
            bigBoxContents: this.wrapper.find('.team-slider-item .text-wrapper .about-item'),
            bigBoxTriggers: this.wrapper.find('.team-slider-item .js-open-team-desc'),
            bigBox: this.wrapper.find('.team-item-big-box'),
            bigBoxContent: this.wrapper.find('.team-item-big-box .team-item-big-box__content'),
            bigBoxClose: this.wrapper.find('.team-item-big-box .close')
        };

        this.UI.bigBoxClose.on('click', e => {
            e.preventDefault();
            this.closeBigBox();
        });

        this.UI.bigBoxTriggers.each((i, btn) => {
            $(btn).on('click', e => {
                e.preventDefault();
                this.openBigBox(parseInt($(btn).attr('data-index'), 10));
            });
        });

        this.UI.bigBox.slideUp(0);
    }

    closeBigBox() {
        this.UI.bigBox.slideUp(350, () => {
            this.UI.bigBoxContent.empty();
        });
    }

    openBigBox(index) {
        this.closeBigBox();

        setTimeout(() => {
            this.UI.bigBoxContent.html(this.UI.bigBoxContents.eq(index).html());

            setTimeout(() => {
                this.UI.bigBox.slideDown(350);
            }, 0);
        }, 400);
    }
}