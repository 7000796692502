/*
 * Tabs script
 */
class Orion_Tabs {
  constructor () {        
    this.initState();     
  }

  initState () {
    this.currentIndex = 0;
    this.win = jQuery(window);
    this.wrapper = $('.js-tabs');
    this.tabs = this.wrapper.find('.tablinks-wrapper .tablinks');
    this.tabsSelect = this.wrapper.find('#faq-category');
    this.contents = this.wrapper.find('.tabcontent-wrapper .tabcontent');
    this.animationInProgress = false;

    if (this.tabs.length) {
      this.initTabsEvents();
    }

    if (this.tabsSelect.length) {
      this.initTabsSelectEvents();
    }
  }

  initTabsEvents () {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs.eq(i).on('click', () => {
        this.tabs.removeClass('is-active');
        this.tabs.eq(i).addClass('is-active');
        this.contents.removeClass('is-active');
        this.contents.eq(i).addClass('is-active');
        this.animationInProgress = true;
        
        if(this.contents.eq(i).find('.tabcontent-list').length) {
          this.contents.eq(i).find('.tabcontent-list .trips-list').css('margin-left', 0);
          this.contents.eq(i).find('.tabcontent-list .trips-list').attr('data-current-item', 0);
          this.contents.eq(i).find('.prev').addClass('is-disabled')
        }
      });
    }
  }

  initTabsSelectEvents () {
    this.tabsSelect.on('change', (e) => {
      $(e.target).find('option:selected').each(() => {
        let optionValue = $(e.target).find('option:selected').attr('value');

        if(optionValue) {
          $('.faq-content-item').not('[data-id="' + optionValue + '"]').removeClass('active');
          $(".faq-content-item[data-id='" + optionValue + "']").addClass('active');
        } else {
          $(".faq-content-item").removeClass('active');
        }
      });
    }).change();
  }
}
