/**
 * Text slider script
 */
class Orion_TextSlider {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState() {
    this.sliderWrapper = $('.text-slider');
    this.dotsList = this.sliderWrapper.find('.slider-dots');
    this.dots = this.sliderWrapper.find('.dot');
    this.prevBtn = this.sliderWrapper.find('.prev');
    this.nextBtn = this.sliderWrapper.find('.next');
    this.list = this.sliderWrapper.find('.text-slider-list');
    this.items = this.list.find('.text-slider-item');
    this.currentItem = 0;
    this.animationInProgress = false;
  }

  initEvents() {
    if (this.sliderWrapper.length) {
      this.swipeEffect = new Hammer(this.sliderWrapper[0]);

      this.nextBtn.on('click', e => {
        e.preventDefault();

        if (this.animationInProgress) {
          return;
        }

        this.currentItem++;
        this.animationInProgress = true;

        if (this.currentItem >= this.items.length) {
          this.currentItem = 0;
        }

        this.dots.removeClass('is-active');
        this.dots.eq(this.currentItem).addClass('is-active');
        this.list.css('margin-left', (-100 * this.currentItem) + "%");

        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });

      this.prevBtn.on('click', e => {
        e.preventDefault();

        if (this.animationInProgress) {
          return;
        }

        this.currentItem--;
        this.animationInProgress = true;

        if (this.currentItem < 0) {
          this.currentItem = this.items.length - 1;
        }

        this.dots.removeClass('is-active');
        this.dots.eq(this.currentItem).addClass('is-active');
        this.list.css('margin-left', (-100 * this.currentItem) + "%");

        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });

      for (let i = 0; i < this.dots.length; i++) {
        this.dots.eq(i).on('click', e => {
          e.preventDefault();
  
          if (this.animationInProgress) {
            return;
          }
  
          this.currentItem = i;
          this.animationInProgress = true;
          this.dots.removeClass('is-active');
          this.dots.eq(this.currentItem).addClass('is-active');
          this.list.css('margin-left', (-100 * this.currentItem) + "%");
          
          setTimeout(() => {
            this.animationInProgress = false;
          }, 550);
        });
      }

      this.swipeEffect.on('swipe', ev => {
        if (ev.type === 'swipe' && ev.direction === 2) {
          this.nextBtn.trigger('click');
        }
  
        if (ev.type === 'swipe' && ev.direction === 4) {
          this.prevBtn.trigger('click');
        }
      });
    }
  }
}
