class Orion_Cookies {
 constructor() {
  this.cookiesInit();
 }
 cookiesInit() {
  function setCookie(cName, cValue, expDays) {
   let date = new Date()
   date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
   const expires = 'expires=' + date.toUTCString()
   document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/'
  }

  function getCookie(cName) {
   const name = cName + '='
   const cCoded = decodeURIComponent(document.cookie)
   const cArr = cCoded.split('; ')
   let value
   cArr.forEach(val => {
    if (val.indexOf(name) === 0) value = val.substring(name.length)
   })

   return value
  }

  const checkConsets = () => {
   document.querySelector('.cookie').style.display = 'none'
   const consents = true
   setCookie('cookies-orion', JSON.stringify(consents), 30);
  }

  document.querySelector('.cookie_button').addEventListener('click', () => {
   checkConsets();
  })

  document.querySelector('.cookie_close').addEventListener('click', () => {
   document.querySelector('.cookie').style.display = 'none';
  })

  function cookieMess() {
   if (!getCookie('cookies-orion')) {
    document.querySelector('.cookie').style.display = 'block';
   } else {
    checkConsets();
   }
  }

  window.addEventListener('load', cookieMess)
 }
}