/**
 * Gallery slider script
 */
class Orion_GallerySlider {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState() {
    this.sliderWrapper = $('.gallery-slider');
    this.prevBtn = this.sliderWrapper.find('.prev');
    this.nextBtn = this.sliderWrapper.find('.next');
    this.list = this.sliderWrapper.find('.gallery-slider-list');
    this.items = this.list.find('.gallery-slider-item');
    this.currentItem = 0;
    this.itemsPerPage = 3;
    this.animationInProgress = false;
  }

  initEvents() {
    this.initRWD();

    if (this.sliderWrapper.length) {
      this.initUI();
    }
  }

  initUI () {        
    this.swipeEffect = new Hammer(this.sliderWrapper[0]);

    this.prevBtn.on('click', e => {
      e.preventDefault();

      if (this.animationInProgress) {
        return;
      }

      this.currentItem--;
      this.animationInProgress = true;

      if (this.currentItem === 0) {
        this.prevBtn.addClass('is-hidden');
      }

      this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
      this.nextBtn.removeClass('is-hidden');

      setTimeout(() => {
          this.animationInProgress = false;
      }, 250);
    });

    this.nextBtn.on('click', e => {
      e.preventDefault();

      if (this.animationInProgress) {
        return;
      }

      this.currentItem++;
      this.animationInProgress = true;

      if (this.currentItem === (this.items.length - this.itemsPerPage)) {
        this.nextBtn.addClass('is-hidden');
      }

      if (this.currentItem >= this.items.length) {
        this.currentItem = (this.items.length - 1);
      }

      this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
      this.prevBtn.removeClass('is-hidden');

      setTimeout(() => {
        this.animationInProgress = false;
      }, 250);
    });

    this.swipeEffect.on('swipe', ev => {
      if (ev.type === 'swipe' && ev.direction === 2 && ev.deltaX < -30) {
        if (this.currentItem > this.items.length - this.itemsPerPage) {
          return
        }

        this.nextBtn.trigger('click');
      }

      if (ev.type === 'swipe' && ev.direction === 4 && ev.deltaX > 30) {
        if (this.currentItem <= 0) {
          return
        }

        this.prevBtn.trigger('click');
      }
    });
  }

  initRWD () {
    this.desktopViewport = window.matchMedia('(min-width: 1024px)');
    this.tabletViewport = window.matchMedia('(min-width: 720px) and (max-width: 1023px)');
    this.mobileViewport = window.matchMedia('(max-width: 719px)');
    if (this.desktopViewport.matches) this.updateViewports('desktop');
    if (this.tabletViewport.matches) this.updateViewports('tablet');
    if (this.mobileViewport.matches) this.updateViewports('mobile');
    this.desktopViewport.addListener(mq => mq.matches ? this.updateViewports('desktop') : false);
    this.tabletViewport.addListener(mq => mq.matches ? this.updateViewports('tablet') : false);
    this.mobileViewport.addListener(mq => mq.matches ? this.updateViewports('mobile') : false);

    if (this.desktopViewport.matches) {
      this.itemsPerPage = 3;
    } else if (this.tabletViewport.matches) {
      this.itemsPerPage = 2;
    } else if (this.mobileViewport.matches) {
      this.itemsPerPage = 1;
    }

    this.desktopViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(3);
      }
    });

    this.tabletViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(2);
      }
    });

    this.mobileViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(1);
      }
    });
  }

  updateViewports (type) {
    if (this.currentViewport !== type) {
      this.currentItem = 0;
      this.list.css('margin-left', "0%");
      this.currentViewport = type;
      this.prevBtn.addClass('is-hidden');
      this.nextBtn.removeClass('is-hidden');
    }
  }

  setItemsPerPage (numberOfItems) {
    this.currentItem = 0;
    this.itemsPerPage = numberOfItems;
    this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");  
  }
}
