/**
 * Small slider script
 */
class Orion_smallSlider {
    constructor(wrapper) {
        this.sliderWrapper = wrapper;
        this.initEvents();
    }

    initEvents() {
        this.prevBtn = this.sliderWrapper.find('.prev');
        this.nextBtn = this.sliderWrapper.find('.next');
        this.dotsList = this.sliderWrapper.find('.slider-dots');
        this.dots = this.sliderWrapper.find('.dot');
        this.list = this.sliderWrapper.find('.slider-list');
        this.items = this.list.find('.slider-item');
        this.currentItem = this.list.attr('data-current-item');
        this.itemsPerPage = 3;
        this.animationInProgress = false;

        this.initRWD();

        if (this.sliderWrapper.length) {
            this.initUI();
        }
    }

    initUI() {
        if (this.items.length <= this.itemsPerPage) {
            this.nextBtn.addClass('is-hidden');
        }

        this.swipeEffect = new Hammer(this.sliderWrapper[0]);

        this.prevBtn.on('click', e => {
            e.preventDefault();

            if (this.animationInProgress) {
                return;
            }

            this.currentItem--;
            this.animationInProgress = true;

            if (this.currentItem === 0) {
                this.prevBtn.addClass('is-hidden');
            }

            this.dots.removeClass('is-active');
            this.dots.eq(this.currentItem).addClass('is-active');
            this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
            this.nextBtn.removeClass('is-hidden');

            setTimeout(() => {
                this.animationInProgress = false;
            }, 250);
        });

        this.nextBtn.on('click', e => {
            e.preventDefault();

            if (this.animationInProgress) {
                return;
            }

            this.currentItem++;
            this.animationInProgress = true;

            if (this.currentItem === (this.items.length - this.itemsPerPage)) {
                this.nextBtn.addClass('is-hidden');
            }

            if (this.currentItem >= this.items.length) {
                this.currentItem = (this.items.length - 1);
            }

            this.dots.removeClass('is-active');
            this.dots.eq(this.currentItem).addClass('is-active');
            this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
            this.prevBtn.removeClass('is-hidden');

            setTimeout(() => {
                this.animationInProgress = false;
            }, 250);
        });

        for (let i = 0; i < this.dots.length; i++) {
            this.dots.eq(i).on('click', e => {
                e.preventDefault();

                if (this.animationInProgress) {
                    return;
                }

                this.currentItem = i;
                this.animationInProgress = true;
                this.dots.removeClass('is-active');
                this.dots.eq(this.currentItem).addClass('is-active');
                this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");

                if (this.currentItem === (this.items.length - this.itemsPerPage)) {
                    this.nextBtn.addClass('is-hidden');
                } else {
                    this.nextBtn.removeClass('is-hidden');
                }

                if (this.currentItem > 0) {
                    this.prevBtn.removeClass('is-hidden');
                } else {
                    this.prevBtn.addClass('is-hidden');
                }

                setTimeout(() => {
                    this.animationInProgress = false;
                }, 550);
            });
        }

        this.swipeEffect.on('swipe', ev => {
            if (ev.type === 'swipe' && ev.direction === 2 && ev.deltaX < -30) {
                if (this.currentItem < this.items.length - this.itemsPerPage) {
                    this.currentItem++;
                } else {
                    this.currentItem = 0;
                }

                this.dots.eq(this.currentItem).trigger('click');
            }

            if (ev.type === 'swipe' && ev.direction === 4 && ev.deltaX > 30) {
                if (this.currentItem > 0) {
                    this.currentItem--;
                } else {
                    this.currentItem = this.items.length - this.itemsPerPage;
                }

                this.dots.eq(this.currentItem).trigger('click');
            }
        });
    }

    initRWD() {
        this.desktopViewport = window.matchMedia('(min-width: 841px)');
        this.tabletViewport = window.matchMedia('(min-width: 561px) and (max-width: 840px)');
        this.mobileViewport = window.matchMedia('(max-width: 560px)');
        if (this.desktopViewport.matches) this.updateViewports('desktop');
        if (this.tabletViewport.matches) this.updateViewports('tablet');
        if (this.mobileViewport.matches) this.updateViewports('mobile');
        this.desktopViewport.addListener(mq => mq.matches ? this.updateViewports('desktop') : false);
        this.tabletViewport.addListener(mq => mq.matches ? this.updateViewports('tablet') : false);
        this.mobileViewport.addListener(mq => mq.matches ? this.updateViewports('mobile') : false);

        if (this.desktopViewport.matches) {
            this.itemsPerPage = 3;
        } else if (this.tabletViewport.matches) {
            this.itemsPerPage = 2;
        } else if (this.mobileViewport.matches) {
            this.itemsPerPage = 1;
        }

        this.toggleDotsVisibility();

        this.desktopViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(3);
            }
        });

        this.tabletViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(2);
            }
        });

        this.mobileViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(1);
            }
        });
    }

    updateViewports(type) {
        if (this.currentViewport !== type) {
            this.currentItem = 0;
            this.list.css('margin-left', "0%");
            this.currentViewport = type;
            this.prevBtn.addClass('is-hidden');
            this.nextBtn.removeClass('is-hidden');
        }
    }

    setItemsPerPage(numberOfItems) {
        this.currentItem = 0;
        this.itemsPerPage = numberOfItems;
        this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
        this.toggleDotsVisibility();
    }

    toggleDotsVisibility() {
        let visibleDots = (this.dots.length - this.itemsPerPage) + 1;

        for (let i = 0; i < this.dots.length; i++) {
            let visibilityState = i < visibleDots ? 'block' : 'none';
            this.dots.eq(i).css('display', visibilityState);
        }

        if (this.itemsCount - this.itemsPerPage <= 0) {
            this.dotsList.css('display', 'none');
        } else {
            this.dotsList.css('display', 'flex');
        }
    }
}
